import { graphql } from "gatsby"
import React from "react"
import PostHeader from "./PostHeader"

export const fragment = graphql`
  fragment postHeaderFragment on WpPost_Postslayouts_PostObject_PostHeader {
    postTitle
    description
    mainImage {
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            width: 720
            quality: 75
            placeholder: NONE
          )
        }
      }
    }

  }
`

export const ACFPostHeader = ({
  breadcrumbData,
  post,
  postTitle,
  description,
  mainImage,
  config
}) => {
  return (
    <PostHeader
      breadcrumbData={breadcrumbData}
      post={post}
      postTitle={postTitle}
      description={description}
      mainImage={mainImage}
    />
  )
}

