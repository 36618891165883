// extracted by mini-css-extract-plugin
export var bgGrey100 = "PostHeader-module--bg-grey-100--b650b";
export var bgGrey150 = "PostHeader-module--bg-grey-150--78e15";
export var bgGrey200 = "PostHeader-module--bg-grey-200--daad9";
export var bgGrey300 = "PostHeader-module--bg-grey-300--943ed";
export var bgGrey400 = "PostHeader-module--bg-grey-400--68dbb";
export var bgGrey500 = "PostHeader-module--bg-grey-500--d6d34";
export var bgGrey600 = "PostHeader-module--bg-grey-600--b147f";
export var bgGrey700 = "PostHeader-module--bg-grey-700--af602";
export var bgGrey800 = "PostHeader-module--bg-grey-800--9734d";
export var bgGrey900 = "PostHeader-module--bg-grey-900--69adc";
export var contentCls = "PostHeader-module--contentCls--20989";
export var imgWrapperCls = "PostHeader-module--imgWrapperCls--82300";
export var textBkgdCls = "PostHeader-module--textBkgdCls--b84e6";
export var textGrey100 = "PostHeader-module--text-grey-100--d7291";
export var textGrey150 = "PostHeader-module--text-grey-150--4e0b2";
export var textGrey200 = "PostHeader-module--text-grey-200--f29c5";
export var textGrey300 = "PostHeader-module--text-grey-300--7e8c5";
export var textGrey400 = "PostHeader-module--text-grey-400--dd2de";
export var textGrey500 = "PostHeader-module--text-grey-500--f7428";
export var textGrey600 = "PostHeader-module--text-grey-600--7df56";
export var textGrey700 = "PostHeader-module--text-grey-700--f902e";
export var textGrey800 = "PostHeader-module--text-grey-800--0b885";
export var textGrey900 = "PostHeader-module--text-grey-900--c11e7";