import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"
import Breadcrumb from "../../UI/Breadcrumb/Breadcrumb"
import { Header } from "../../UI/Common"

import {
  contentCls,
  textBkgdCls,
  imgWrapperCls,
} from "./PostHeader.module.scss"

const PostHeader = ({
  breadcrumbData,
  post,
  postTitle,
  description,
  mainImage,
  config,
}) => {
  const { padding } = config || {}

  const parsedDate = new Date(`${post.date}Z`)
  const formattedDate = parsedDate.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  return (
    <Header padding={padding}>
      {breadcrumbData && (
        <div className="bg-secondary pb-lg-3">
          <Container>
            <Breadcrumb data={breadcrumbData} className="bg-secondary px-0" />
          </Container>
        </div>
      )}
      <div className={contentCls}>
        <div className={textBkgdCls} />
        <Container>
          <Row>
            <Col xs={12} lg={6} key={uuidv4()} className="my-3 my-lg-0">
              <h1 className=" my-md-3">{postTitle}</h1>
              <div className="my-4">{description}</div>
              <div>{formattedDate}</div>
            </Col>
            {mainImage && (
              <Col xs={12} lg={6} key={uuidv4()} className="my-3 my-lg-0">
                <div className="position-relative">
                  <div className={imgWrapperCls}>
                    <GatsbyImage
                      className="h-100"
                      alt={mainImage.altText || ""}
                      image={getImage(mainImage.localFile)}
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Header>
  )
}

export default PostHeader
